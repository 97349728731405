// Generated by Framer (89417f6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["DfexZPbAa", "A_5q62zqv", "KrQq3SjLx"];

const variantClassNames = {A_5q62zqv: "framer-v-pgimhj", DfexZPbAa: "framer-v-1nd7x7", KrQq3SjLx: "framer-v-18cftj3"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translateY(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "DfexZPbAa", "Variant 4": "A_5q62zqv", "Variant 5": "KrQq3SjLx"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, oYbSbAW8q: title ?? props.oYbSbAW8q ?? "Design Studio Next Gen", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "DfexZPbAa"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, oYbSbAW8q, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "DfexZPbAa", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const isDisplayed = () => {
if (baseVariant === "A_5q62zqv") return false
return true
}

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-EnNba", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1nd7x7", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"DfexZPbAa"} ref={ref} style={{...style}} {...addPropertyOverrides({A_5q62zqv: {"data-framer-name": "Variant 4"}, KrQq3SjLx: {"data-framer-name": "Variant 5"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7TGVhZ3VlIFNwYXJ0YW4tNzAw", "--framer-font-family": "\"League Spartan\", \"League Spartan Placeholder\", sans-serif", "--framer-font-weight": "700", "--framer-line-height": "100px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}><motion.span style={{"--framer-font-size": "89.3333px"}}>Design Studio Next Gen</motion.span><motion.span style={{"--framer-font-size": "89.3333px"}}><motion.br/></motion.span></motion.p></React.Fragment>} className={"framer-zxj1o1"} data-framer-name={"Nadpis"} fonts={["GF;League Spartan-700"]} layoutDependency={layoutDependency} layoutId={"jKgq7s2mn"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={oYbSbAW8q} transformTemplate={transformTemplate} verticalAlignment={"top"} withExternalLayout/>)}</motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-EnNba [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-EnNba .framer-1oqsb3g { display: block; }", ".framer-EnNba .framer-1nd7x7 { height: 235px; overflow: hidden; position: relative; width: 639px; }", ".framer-EnNba .framer-zxj1o1 { -webkit-user-select: none; flex: none; height: auto; left: 0px; pointer-events: auto; position: absolute; right: 0px; top: 50%; user-select: none; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 235
 * @framerIntrinsicWidth 639
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"A_5q62zqv":{"layout":["fixed","fixed"]},"KrQq3SjLx":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"oYbSbAW8q":"title"}
 * @framerImmutableVariables false
 */
const FramerBTOAVadXi: React.ComponentType<Props> = withCSS(Component, css, "framer-EnNba") as typeof Component;
export default FramerBTOAVadXi;

FramerBTOAVadXi.displayName = "Nadpis";

FramerBTOAVadXi.defaultProps = {height: 235, width: 639};

addPropertyControls(FramerBTOAVadXi, {variant: {options: ["DfexZPbAa", "A_5q62zqv", "KrQq3SjLx"], optionTitles: ["Variant 1", "Variant 4", "Variant 5"], title: "Variant", type: ControlType.Enum}, oYbSbAW8q: {defaultValue: "Design Studio Next Gen", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerBTOAVadXi, [{family: "League Spartan", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/BTOAVadXi:default", url: "https://fonts.gstatic.com/s/leaguespartan/v11/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvMGYPpBMdcFguczA.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/leaguespartan/v11/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvMGYPpBMdcFguczA.ttf", weight: "700"}])